import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import {
  getInternDetail,
  getInternPayment,
  getInternDetailApplication,
  getInternResume
} from '../../../api/member'
import { getUrlParams, setDetailDateFormat, isEmpty, setServerUrl } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const UserManagement = () => {
  const dispatch = useAppDispatch()

  const [basicInfo, setBasicInfo] = useState(null)
  const [payment, setPayment] = useState([])
  const [recordsPayTotal, setRecordsPayTotal] = useState(0)
  const [matching, setMatching] = useState([])
  const [resume, setResume] = useState([])
  const [recordsMatchingTotal, setRecordsMatchingTotal] = useState(0)

  useEffect(() => {
    dispatch(setLoading(false))

    // ?uid=1&iid=1
    // 기본정보
    callBasicInfo(getUrlParams(window.location.href))
    // 결제내역
    callPayment(getUrlParams(window.location.href))
    // 매칭내역
    callMatching(getUrlParams(window.location.href))
    // 이력서
    calResumeInfo(getUrlParams(window.location.href))
    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const callBasicInfo = async (param) => {
    if (Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getInternDetail(param.iid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        const chkAddressKey = ['addressDetails', 'address', 'city', 'state', 'postcode']
        const joinAddress = []
        for(let i = 0; i<chkAddressKey.length; i++ ) {
          if(Object.keys(data).includes(chkAddressKey[i])) {
            if(!isEmpty(data?.[chkAddressKey[i]])) {
              joinAddress.push(data?.[chkAddressKey[i]])
            }
          }
        }

        setBasicInfo({ ...data, addressSum: joinAddress.join(', ') || '-' })
      } else {
        setBasicInfo({})
      }
    }
  }

  const callPayment = async (param) => {
    if (Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getInternPayment(param.uid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        setPayment([...data.payments])
        setRecordsPayTotal(data.totalCount)
      } else {
        setPayment([])
        setRecordsPayTotal(0)
      }
    }
  }

  const callMatching = async (param) => {
    if (Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getInternDetailApplication(
        param.uid
      ).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        setMatching([...data.items])
        setRecordsMatchingTotal(data.totalCount)
      } else {
        setMatching([])
        setRecordsMatchingTotal(0)
      }
    }
  }

  const calResumeInfo = async (param) => {
    if (Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getInternResume(param.uid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        const chkAddressKey = ['addressDetails', 'address', 'city', 'state', 'postcode']
        const joinAddress = []
        for(let i = 0; i<chkAddressKey.length; i++ ) {
          if(Object.keys(data).includes(chkAddressKey[i])) {
            if(!isEmpty(data?.[chkAddressKey[i]])) {
              joinAddress.push(data?.[chkAddressKey[i]])
            }
          }
        }

        setResume({ ...data, addressSum: joinAddress.join(', ') || '-' })
      } else {
        setResume({})
      }
    }
  }

  const snsTypeUrl = (type, id) => {
    if(isEmpty(id)) {
      return ''
    }else {
      if(type === 'instagram') return `https://www.instagram.com/${id}`
      else if(type === 'facebook') return `https://www.facebook.com/${id}`
      else if(type === 'twitter') return `https://twitter.com/${id}`
    }
  }

  const combineViewer = (param, param2, divider = null) => {
    const arr = []
    if(!isEmpty(param)) {
      arr.push(param)
    }
    if(!isEmpty(param2)) {
      arr.push(param2)
    }
    const joinArr = arr.join(divider ? divider : ' ')

    return joinArr.length > 0 ? joinArr : '-'
  }

  const combineViewerArr = (param, keys, divider = null) => {
    const arr = []

    if(param.length > 0 && !isEmpty(keys)) {
      param?.map((item) => {
        arr.push(item?.[keys])
      })
    }
    const joinArr = arr.join(divider ? divider : ' ')

    return joinArr.length > 0 ? joinArr : '-'
  }


  return (
    <Fragment>
      <Container>
        <div className="contents">
          {/** 기본정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
              <h6 className="table-title">기본정보</h6>
            </div>
            <div className="table-body">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                  <col width="15%" />
                  <col width="35%" />
                  <col width="15%" />
                  <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">ID</td>
                    <td className="tac">{basicInfo?.user?.email || '-'}</td>
                    <td className="tac bg">이름</td>
                    <td className="tac">{combineViewer(basicInfo?.user?.firstName, basicInfo?.user?.lastName)}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">성별</td>
                    <td className="tac">{basicInfo?.genderEn || '-'}</td>
                    <td className="tac bg">생년월일</td>
                    <td className="tac">{basicInfo?.birthday || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">국적</td>
                    <td className="tac">{basicInfo?.nationality || '-'}</td>
                    <td className="tac bg">연락처</td>
                    <td className="tac">{basicInfo?.phone ? basicInfo?.phone?.replace(' ', '') : '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">주소</td>
                    <td className="tac" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{basicInfo?.addressSum || '-'}</td>
                    <td className="tac bg">SNS계정</td>
                    <td className="tac">
                      {
                        basicInfo?.snsAccount
                        ?

                          <a href={snsTypeUrl(basicInfo?.snsType, basicInfo?.snsAccount)}
                            target="_blank"
                            rel="noopener noreferrer">
                              {basicInfo?.snsType == 'facebook' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_facebook.png')}
                                />
                              )}
                              {basicInfo?.snsType == 'instagram' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_insta.png')}
                                />
                              )}
                              {basicInfo?.snsType == 'twitter' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_twitter.png')}
                                />
                              )}
                              <u style={{color: '#0000ff'}}>{basicInfo?.snsAccount}</u>
                          </a>
                        : '-'
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">회원가입일</td>
                    <td className="tac">
                      {basicInfo?.user?.createdAt != '' &&
                      basicInfo?.user?.createdAt
                        ? setDetailDateFormat(basicInfo?.user?.createdAt)
                        : '-'}
                    </td>
                    <td className="tac bg">최종로그인</td>
                    <td className="tac">
                      {basicInfo?.user?.lastLoginAt != '' &&
                      basicInfo?.user?.lastLoginAt
                        ? setDetailDateFormat(basicInfo?.user?.lastLoginAt)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">제휴 대학</td>
                    <td className="tac">
                      {basicInfo?.affiliatedUniversity?.name || '-'}
                    </td>
                    <td className="tac bg">기타사항</td>
                    <td className="tac">
                      {basicInfo?.otherInformation || '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/** 이력서 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
              <h6 className="table-title">프로필</h6>
            </div>
            <div className="table-body">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                  <col width="15%" />
                  <col width="35%" />
                  <col width="15%" />
                  <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg" rowSpan="2">프로필사진</td>
                    <td className="tac" rowSpan="2">
                      <img className="preview" 
                        style={{width: "256px", height: "256px"}}
                        src={resume?.images?.length > 0 ? setServerUrl(resume?.images[0]?.url) : require("../../../assets/images/common/p_img1.jpg")} alt="미리보기" htmlFor="a_fileName" 
                      />
                    </td>
                    <td className="tac bg">이름</td>
                    <td className="tac">{combineViewer(resume?.firstName, resume?.lastName)}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">이메일</td>
                    <td className="tac">{resume?.email || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">성별</td>
                    <td className="tac">{resume?.genderEn || '-'}</td>
                    <td className="tac bg">생년월일</td>
                    <td className="tac">{resume?.birthday || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">주소</td>
                    <td colSpan="3">{resume?.addressSum || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">국적</td>
                    <td className="tac">{resume?.nationalityEn || '-'}</td>
                    <td className="tac bg">모국어</td>
                    <td className="tac">{resume?.firstLanguageEn || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">이력서 작성상태</td>
                    <td className="tac">
                      {resume?.resumeStatus}
                    </td>
                    <td className="tac bg">SNS계정</td>
                    <td className="tac">
                      {
                        resume?.snsAccount
                        ?

                          <a href={snsTypeUrl(resume?.snsType, resume?.snsAccount)}
                            target="_blank"
                            rel="noopener noreferrer">
                              {resume?.snsType == 'facebook' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_facebook.png')}
                                />
                              )}
                              {resume?.snsType == 'instagram' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_insta.png')}
                                />
                              )}
                              {resume?.snsType == 'twitter' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_twitter.png')}
                                />
                              )}
                              <u style={{color: '#0000ff'}}>{resume?.snsAccount}</u>
                          </a>
                        : '-'
                      }
                    </td>
                  </tr>
                  <tr>
                  <td className="tac bg">연락처</td>
                    <td className="tac">{resume?.phone ? resume?.phone?.replace(' ', '') : '-'}</td>
                    <td className="tac bg">기타사항</td>
                    <td className="tac">
                      {resume?.otherInformation || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">자기 소개서</td>
                    <td className="tac">
                      {resume?.coverLetter || '-'}
                    </td>
                    <td className="tac bg">일(Task)</td>
                    <td className="tac">
                      {resume?.task || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">등록일</td>
                    <td className="tac">
                      {resume?.createdAt != '' &&
                      resume?.createdAt
                        ? setDetailDateFormat(resume?.createdAt)
                        : '-'}
                    </td>
                    <td className="tac bg">수정일</td>
                    <td className="tac">
                      {resume?.updatedAt != '' &&
                      resume?.updatedAt
                        ? setDetailDateFormat(resume?.updatedAt)
                        : '-'}
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="tac bg">소속대학 학년</td>
                    <td className="tac">
                      {resume?.affiliatedUniversity?.grade || '-'}
                    </td>
                    <td className="tac bg">소속대학 학과</td>
                    <td className="tac">
                      {resume?.affiliatedUniversity?.major || '-'}
                    </td>
                  </tr> */}
                  {
                    resume?.periods?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기간</td>
                          <td className="tac" colSpan="3">
                           {`${combineViewer(resume?.periods[0]?.startDate, resume?.periods[0]?.endDate, ' ~ ')} (${resume?.periods[0]?.durationEn})`}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.skills?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">능력</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.skills, 'skillEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.workTypes?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">업무 타입</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.workTypes, 'workTypeEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.jobPositions?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 업무</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.jobPositions, 'jobPositionEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.objectives?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">지원 목적</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.objectives, 'objectiveEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.certificates?.length > 0
                    ?
                      (
                        resume?.certificates?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">자격증{`(${idx+1})`}</td>
                          </tr>
                          <tr key={`${idx}-1`}>
                            <td className="tac bg">자격증명</td>
                            <td className="tac">
                              {item?.certificateEn || '-'}
                            </td>
                            <td className="tac bg">자격증 유효기간</td>
                            <td className="tac">
                              {combineViewer(item?.startDate, item?.endDate, ' ~ ')}
                            </td>
                          </tr>
                          <tr key={`${idx}-2`}>
                            <td className="tac bg">자격증 설명</td>
                            <td className="tac" colSpan="3">
                              {item?.certificateDescription || '-'}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  <tr>
                    <td className="tac bg" colSpan="4">희망 기업 정보</td>
                  </tr>
                  {
                    resume?.companyCountries?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기업 국가</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.companyCountries, 'countryEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.companyCultures?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기업 문화</td>
                          <td className="tac" colSpan="3" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {combineViewerArr(resume?.companyCultures, 'companyCultureEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.companySizes?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기업 규모</td>
                          <td className="tac" colSpan="3" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {combineViewerArr(resume?.companySizes, 'companySizeEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.companySupports?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기업 복지</td>
                          <td className="tac" colSpan="3" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {combineViewerArr(resume?.companySupports, 'companySupportEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.experiences?.length > 0
                    ?
                      (
                        resume?.experiences?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">인턴 경험{`(${idx+1})`}</td>
                          </tr>
                          <tr key={`${idx}-1`}>
                            <td className="tac bg">기업명</td>
                            <td className="tac">
                              {item?.companyName || '-'}
                            </td>
                            <td className="tac bg">기간</td>
                            <td className="tac">
                              {`${combineViewer(item?.startDate, item?.endDate, ' ~ ')} (${item?.durationEn})`}
                            </td>
                          </tr>
                          <tr key={`${idx}-2`}>
                            <td className="tac bg">구분</td>
                            <td className="tac">
                              {item?.industryCategoryEn || '-'}
                            </td>
                            <td className="tac bg">담당한 일</td>
                            <td className="tac">
                              {item?.job || '-'}
                            </td>
                          </tr>
                          <tr key={`${idx}-3`}>
                            <td className="tac bg">결과</td>
                            <td className="tac">
                              {item?.outcome || '-'}
                            </td>
                            <td className="tac bg">포지션</td>
                            <td className="tac">
                              {item?.position || '-'}
                            </td>
                          </tr>
                          <tr key={`${idx}-4`}>
                            <td className="tac bg">급여</td>
                            <td className="tac" colSpan="3">
                              {item?.salary || '-'}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  {
                    resume?.highSchools?.length > 0
                    ?
                      (
                        resume?.highSchools?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">고등학교{`(${idx+1})`}</td>
                          </tr>
                          <tr key={idx}>
                            <td className="tac bg">고등학교명</td>
                            <td className="tac">
                              {item?.name || '-'}
                            </td>
                            <td className="tac bg">재학기간</td>
                            <td className="tac">
                              {combineViewer(item?.startDate, item?.endDate, ' ~ ')}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  {
                    resume?.universities?.length > 0
                    ?
                      (
                        resume?.universities?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">대학교{`(${idx+1})`}</td>
                          </tr>
                          <tr key={`${idx}-1`}>
                            <td className="tac bg">대학교명</td>
                            <td className="tac">
                              {item?.name || '-'}
                            </td>
                            <td className="tac bg">재학기간</td>
                            <td className="tac">
                              {combineViewer(item?.startDate, item?.endDate, ' ~ ')}
                            </td>
                          </tr>
                          <tr key={`${idx}-2`}>
                            <td className="tac bg">자격</td>
                            <td className="tac">
                              {item?.qualificationEn || '-'}
                            </td>
                            <td className="tac bg">연구분야</td>
                            <td className="tac">
                              {item?.studyFieldEn}
                            </td>
                          </tr>
                          <tr key={`${idx}-3`}>
                            <td className="tac bg">활동이력</td>
                            <td className="tac" colSpan="3">
                              {item?.universityActivity || '-'}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  {
                    resume?.otherLanguages?.length > 0
                    ?
                      (
                        resume?.otherLanguages?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">외국어{`(${idx+1})`}</td>
                          </tr>
                          <tr key={idx}>
                            <td className="tac bg">외국어</td>
                            <td className="tac">
                              {item?.languageEn || '-'}
                            </td>
                            <td className="tac bg">언어능력</td>
                            <td className="tac">
                            {item?.languageProficiencyEn || '-'}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  {
                    resume?.personalCharacters?.length > 0
                    ?
                      (
                        resume?.personalCharacters?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">수상이력{`(${idx+1})`}</td>
                          </tr>
                          <tr key={`${idx}-1`}>
                            <td className="tac bg">수상이름</td>
                            <td className="tac">
                              {item?.awardName || '-'}
                            </td>
                            <td className="tac bg">설명</td>
                            <td className="tac">
                              {item?.awardDescription || '-'}
                            </td>
                          </tr>
                          <tr key={`${idx}-2`}>
                            <td className="tac bg">수상기간</td>
                            <td className="tac" colSpan="3">
                              {combineViewer(item?.startDate, item?.endDate, ' ~ ')}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                </tbody>
              </table>
            </div>
          </div>
          {/** 결제내역 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
              <h6 className="table-title">결제내역</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                  <col width="55px" />
                  <col width="15%" />
                  <col width="*" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>결제 일시</th>
                    <th>지원 회사</th>
                    <th>지원 직무</th>
                    <th>결제수단</th>
                    <th>결제 구분</th>
                    <th>환불 내역</th>
                  </tr>
                </thead>
                <tbody>
                  {payment?.length != 0 ? (
                    payment.map((item, idx) => (
                      <tr key={idx}>
                        <td className="tac">{recordsPayTotal - idx}</td>
                        <td className="tac">
                          {item?.paidAt != '' && item?.paidAt
                            ? setDetailDateFormat(item?.paidAt)
                            : '-'}
                        </td>
                        <td className="tac">
                          {item?.order?.product?.name || '-'}
                        </td>
                        <td className="tac">
                          {item?.order?.product?.jobPosition || '-'}
                        </td>
                        <td className="tac">{item?.method || '-'}</td>
                        <td className="tac">{item?.status || '-'}</td>
                        <td className="tac">
                          {item?.refundedAt != '' && item?.refundedAt
                            ? setDetailDateFormat(item?.refundedAt)
                            : '-'}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">
                        <div className="table-nopost">
                          조회된 데이터가 없습니다.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/** 매칭 내역 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
              <h6 className="table-title">매칭내역</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                  <col width="55px" />
                  <col width="15%" />
                  <col width="20%" />
                  <col width="*" />
                  <col width="15%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>지원 일시</th>
                    <th>지원 기업명</th>
                    <th>지원 공고</th>
                    <th>진행상태</th>
                  </tr>
                </thead>
                <tbody>
                  {matching?.length != 0 ? (
                    matching.map((item, idx) => (
                      <tr key={idx}>
                        <td className="tac">{recordsMatchingTotal - idx}</td>
                        <td className="tac">
                          {item?.createdAt != '' && item?.createdAt
                            ? setDetailDateFormat(item?.createdAt)
                            : '-'}
                        </td>
                        <td className="tac">
                          {item?.recruitment?.company?.name || '-'}
                        </td>
                        <td className="tac">
                          {item?.recruitment?.title || '-'}
                        </td>
                        <td className="tac">{item?.status || '-'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">
                        <div className="table-nopost">
                          조회된 데이터가 없습니다.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default UserManagement
