import ApiBase from './apiBase'

// API instance
const Api = new ApiBase('/post')

// 게시판 > 인턴쉽 채용 공고
export const getInternshipRecruitment = async (params) => {
  return await Api.submit('get', `internship/recruitments`, params)
}

// 게시판 > 인턴쉽 채용 공고 > 상세 조회
export const getInternshipRecruitmentDetail = async (id) => {
  return await Api.submit('get', `internship/recruitments/${id}`)
}

// 게시판 > 인턴쉽 채용 공고 > 상세 수정
export const updateInternshipRecruitment = async (id, params) => {
  return await Api.submit('post', `internship/recruitments/${id}`, params)
}

// 게시판 > 인턴쉽 채용 공고 > status 수정
export const updateStatus = async (id, params) => {
  return await Api.submit(
    'post',
    `internship/recruitments/status/${id}`,
    params
  )
}

// 게시판 > 인턴쉽 채용 공고 > hot position 수정
export const updateHotPosition = async (id, params) => {
  return await Api.submit(
    'post',
    `internship/recruitments/hotPosition/${id}`,
    params
  )
}

// 게시판 > 인턴쉽 채용 공고 > hot position 수정
export const updateSpecial = async (id, params) => {
  return await Api.submit(
    'post',
    `internship/recruitments/special/${id}`,
    params
  )
}

// 게시판 > 인턴쉽 지원 현황
export const getInternshipApplication = async (params) => {
  return await Api.submit('get', `internship/applications`, params)
}

// 게시판 > 인턴쉽 스토리
export const getInternshipStory = async (params) => {
  return await Api.submit('get', `internship/stories`, params)
}

// 게시판 > 인턴쉽 스토리 > active, best 수정
export const updateInternshipStoryYn = async (id, params) => {
  return await Api.submit(
    'post',
    `internship/stories/detail/setting/${id}`,
    params
  )
}

// 게시판 > 인턴쉽 스토리 > 상세
export const getInternshipStoryDetail = async (id) => {
  return await Api.submit('get', `internship/stories/${id}`)
}

// 게시판 > 인턴쉽 스토리 > 기업명
export const getCompany = async () => {
  return await Api.submit('get', `company`)
}

// 게시판 > 인턴쉽 스토리 > 등록
export const insertInternshipStory = async (params) => {
  return await Api.submit('post', `internship/stories`, params)
}

// 게시판 > 인턴쉽 스토리 > 등록
export const updateInternshipStory = async (id, params) => {
  return await Api.submit('post', `internship/stories/${id}`, params)
}

// 게시판 > 글로벌 캠프 공고
export const getGlobalcamp = async (params) => {
  return await Api.submit('get', `global-camp/recruitments`, params)
}

// 게시판 > 글로벌 캠프 공고 > active, best 수정
export const updateGlobalcampYn = async (id, params) => {
  return await Api.submit(
    'post',
    `global-camp/recruitments/detail/setting/${id}`,
    params
  )
}

// 게시판 > 글로벌 캠프 공고 > 등록
export const insertGlobalcamp = async (params) => {
  return await Api.submit('post', `global-camp/recruitments`, params)
}

// 게시판 > 글로벌 캠프 공고 > 상세
export const getGlobalcampDetail = async (id) => {
  return await Api.submit('get', `global-camp/recruitments/${id}`)
}

// 게시판 > 글로벌 캠프 공고 > 등록
export const updateGlobalcamp = async (id, params) => {
  return await Api.submit('post', `global-camp/recruitments/${id}`, params)
}

// 게시판 > 사전인터뷰 목록
export const getPreInterview = async () => {
  return await Api.submit('get', `pre-interview`)
}

// 게시판 > 사전인터뷰 등록
export const insertPreInterview = async (params) => {
  return await Api.submit('post', `pre-interview`, params)
}

// 게시판 > 사전인터뷰 수정
export const updatePreInterview = async (id, params) => {
  return await Api.submit('post', `pre-interview/${id}`, params)
}

// 게시판 > 사전인터뷰 삭제
export const deletePreInterview = async (id) => {
  return await Api.submit('post', `pre-interview/delete/${id}`)
}
