/* 
  Redux Toolkit 
*/

/* 
  Loading 인포
*/
import { createSlice } from '@reduxjs/toolkit'

function initState() {
  const state = false
  return state
}

export const initialState = initState()
export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    // loading set
    setLoading(state, action) {
      return (state = action.payload)
    },
  },
})

export default loadingSlice.reducer

// actions
export const { setLoading } = loadingSlice.actions
