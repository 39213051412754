/* 
  Redux Toolkit 
*/

/* 
  User 인포
*/
import { createSlice } from '@reduxjs/toolkit'

function initState() {
  const state = {}
  return state
}

export const initialState = initState()
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Actions

    /* 로그인 성공 */
    setUser(state, action) {
      for (const [k, v] of Object.entries(action.payload)) {
        state[k] = v
      }
    },
    /* 로그인 실패 & 로그아웃시 */
    delUser(state) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [k, v] of Object.entries(state)) {
        state[k] = ''
      }
      state = {}
    },
  },
})

export default userSlice.reducer

// actions
export const { setUser, delUser } = userSlice.actions
