import ApiBase from './apiBase'

// API instance
const Api = new ApiBase('/common-codes')

// 관리자 > 공통코드 호출
export const getCommonCodes = async (params) => {
  return await Api.submit('get', ``, { groupCode: params })
}

// 관리자 > 국가코드 호출
export const getCountryCodes = async () => {
  return await Api.submit('get', `country`)
}
