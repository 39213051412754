import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import { getApplicationInternship, updateThunderJobScore, getInternResume } from '../../../api/member'
import { getUrlParams, setDateFormat, setDetailDateFormat, isEmpty, onlyNumber, setServerUrl } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const UserManagement = () => {
  const dispatch = useAppDispatch()

  const [params, setParams] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [application, setApplication] = useState(null)
  const [resume, setResume] = useState([])
  const [score, setScore] = useState(0)

  useEffect(async () => {
    dispatch(setLoading(false))
    
    const paramInit = getUrlParams(window.location.href)
    setParams(paramInit)

    // 기본정보
    await callApplication(paramInit)
    // 이력서
    await calResumeInfo(paramInit)

    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const callApplication = async (param) => {
    if(Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getApplicationInternship({
        orderType: 'INTERNSHIP',
        paymentId: param?.pid,
        orderId: param?.oid,
        searchType: 'APPLICATION',
      }).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200 && data.totalCount > 0) {
        const chkAddressKey = ['addressDetails', 'address', 'city', 'state', 'postcode']
        const joinAddress = []
        for(let i = 0; i<chkAddressKey.length; i++ ) {
          if(Object.keys(data.payments[0]?.order?.product).includes(chkAddressKey[i])) {
            if(!isEmpty(data.payments[0]?.order?.product?.[chkAddressKey[i]])) {
              joinAddress.push(data.payments[0]?.order?.product?.[chkAddressKey[i]])
            }
          }
        }
        setApplication({...data.payments[0], addressSum: joinAddress.join(', ') || '-' })
        setScore(data.payments[0]?.order?.product?.thunderjobAssessment)
      } else {
        setApplication({})
      }
    }
  }
  const calResumeInfo = async (param) => {
    if (Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getInternResume(param.uid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        const chkAddressKey = ['addressDetails', 'address', 'city', 'state', 'postcode']
        const joinAddress = []
        for(let i = 0; i<chkAddressKey.length; i++ ) {
          if(Object.keys(data).includes(chkAddressKey[i])) {
            if(!isEmpty(data?.[chkAddressKey[i]])) {
              joinAddress.push(data?.[chkAddressKey[i]])
            }
          }
        }

        setResume({ ...data, addressSum: joinAddress.join(', ') || '-' })
      } else {
        setResume({})
      }
    }
  }

  const callThunderJobScoreSave = async () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('ThunderJob 점수를 수정하시겠습니까?')) {
      const { code } = await updateThunderJobScore(params?.oid, {score})

      if (code === 200) {
        alert('ThunderJob 점수 수정 성공')
        window.location.reload()
      } else {
        alert('ThunderJob 점수 수정 실패')
      }
    }
  }
  const snsTypeUrl = (type, id) => {
    if(isEmpty(id)) {
      return ''
    }else {
      if(type === 'instagram') return `https://www.instagram.com/${id}`
      else if(type === 'facebook') return `https://www.facebook.com/${id}`
      else if(type === 'twitter') return `https://twitter.com/${id}`
    }
  }

  const combineViewer = (param, param2, divider = null) => {
    const arr = []
    if(!isEmpty(param)) {
      arr.push(param)
    }
    if(!isEmpty(param2)) {
      arr.push(param2)
    }
    const joinArr = arr.join(divider ? divider : ' ')

    return joinArr.length > 0 ? joinArr : '-'
  }

  const combineViewerArr = (param, keys, divider = null) => {
    const arr = []

    if(param.length > 0 && !isEmpty(keys)) {
      param?.map((item) => {
        arr.push(item?.[keys])
      })
    }
    const joinArr = arr.join(divider ? divider : ' ')

    return joinArr.length > 0 ? joinArr : '-'
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          {/** 기본정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">기본 정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">ID</td>
                    <td className="tac">{application?.order?.product?.account || '-'}</td>
                    <td className="tac bg">이름</td>
                    <td className="tac">{application?.order?.product?.userName || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">성별</td>
                    <td className="tac">{application?.order?.product?.genderNm || '-'}</td>
                    <td className="tac bg">생년월일</td>
                    <td className="tac">
                      {application?.order?.product?.birthday != '' && application?.order?.product?.birthday 
                        ? setDateFormat(application?.order?.product?.birthday) 
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">국적</td>
                    <td className="tac">{application?.order?.product?.nationalityNm || '-'}</td>
                    <td className="tac bg">연락처</td>
                    <td className="tac">{application?.order?.product?.phone ? application?.order?.product?.phone?.replace(' ', '') : '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">주소</td>
                    <td className="tac">{application?.addressSum || '-'}</td>
                    <td className="tac bg">SNS계정</td>
                    <td className="tac">
                      {
                        application?.order?.product?.snsAccount
                        ?

                          <a href={snsTypeUrl(application?.order?.product?.snsType, application?.order?.product?.snsAccount)}
                            target="_blank"
                            rel="noopener noreferrer">
                              {application?.order?.product?.snsType == 'facebook' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_facebook.png')}
                                />
                              )}
                              {application?.order?.product?.snsType == 'instagram' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_insta.png')}
                                />
                              )}
                              {application?.order?.product?.snsType == 'twitter' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_twitter.png')}
                                />
                              )}
                              <u style={{color: '#0000ff'}}>{application?.order?.product?.snsAccount}</u>
                          </a>
                        : '-'
                      }
                      {application?.order?.product?.snsId || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">제휴 대학</td>
                    <td className="tac">{application?.order?.product?.universityName || '-'}</td>
                    <td className="tac bg">기타사항</td>
                    <td className="tac">{application?.order?.product?.otherInformation || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/** 지원 정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">지원 정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">지원 공고명</td>
                    <td className="tac">{application?.order?.product?.name || '-'}</td>
                    <td className="tac bg">지원 기업</td>
                    <td className="tac">{application?.order?.product?.companyName || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">지원 직무</td>
                    <td className="tac">{application?.order?.product?.jobPositionCode || '-'}</td>
                    <td className="tac bg">지원 일시</td>
                    <td className="tac">
                      {application?.order?.product?.createdAt != '' && application?.order?.product?.createdAt 
                        ? setDetailDateFormat(application?.order?.product?.createdAt) 
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg" style={{ fontWeight: 'bold' }}>매칭률</td>
                    <td className="tac" style={{ color: application?.order?.product?.matchTotal ? '#ff0000' : '', fontWeight: 'bold' }}>{application?.order?.product?.matchTotal ? `${application?.order?.product?.matchTotal}%` : '-'}</td>
                    <td className="tac bg">합격 여부</td>
                    <td className="tac">{application?.order?.product?.applicationStatus || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/** 이력서 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
              <h6 className="table-title">프로필</h6>
            </div>
            <div className="table-body">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                  <col width="15%" />
                  <col width="35%" />
                  <col width="15%" />
                  <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg" rowSpan="2">프로필사진</td>
                    <td className="tac" rowSpan="2">
                      <img className="preview" 
                        style={{width: "256px", height: "256px"}}
                        src={resume?.images?.length > 0 ? setServerUrl(resume?.images[0]?.url) : require("../../../assets/images/common/p_img1.jpg")} alt="미리보기" htmlFor="a_fileName" 
                      />
                    </td>
                    <td className="tac bg">이름</td>
                    <td className="tac">{combineViewer(resume?.firstName, resume?.lastName)}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">이메일</td>
                    <td className="tac">{resume?.email || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">성별</td>
                    <td className="tac">{resume?.genderEn || '-'}</td>
                    <td className="tac bg">생년월일</td>
                    <td className="tac">{resume?.birthday || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">주소</td>
                    <td colSpan="3">{resume?.addressSum || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">국적</td>
                    <td className="tac">{resume?.nationalityEn || '-'}</td>
                    <td className="tac bg">모국어</td>
                    <td className="tac">{resume?.firstLanguageEn || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">이력서 작성상태</td>
                    <td className="tac">
                      {resume?.resumeStatus}
                    </td>
                    <td className="tac bg">SNS계정</td>
                    <td className="tac">
                      {
                        resume?.snsAccount
                        ?

                          <a href={snsTypeUrl(resume?.snsType, resume?.snsAccount)}
                            target="_blank"
                            rel="noopener noreferrer">
                              {resume?.snsType == 'facebook' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_facebook.png')}
                                />
                              )}
                              {resume?.snsType == 'instagram' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_insta.png')}
                                />
                              )}
                              {resume?.snsType == 'twitter' && (
                                <img
                                  className='sns_ico'
                                  src={require('../../../assets/images/common/icon_twitter.png')}
                                />
                              )}
                              <u style={{color: '#0000ff'}}>{resume?.snsAccount}</u>
                          </a>
                        : '-'
                      }
                    </td>
                  </tr>
                  <tr>
                  <td className="tac bg">연락처</td>
                    <td className="tac">{resume?.phone ? resume?.phone?.replace(' ', '') : '-'}</td>
                    <td className="tac bg">기타사항</td>
                    <td className="tac">
                      {resume?.otherInformation || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">자기 소개서</td>
                    <td className="tac">
                      {resume?.coverLetter || '-'}
                    </td>
                    <td className="tac bg">일(Task)</td>
                    <td className="tac">
                      {resume?.task || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">등록일</td>
                    <td className="tac">
                      {resume?.createdAt != '' &&
                      resume?.createdAt
                        ? setDetailDateFormat(resume?.createdAt)
                        : '-'}
                    </td>
                    <td className="tac bg">수정일</td>
                    <td className="tac">
                      {resume?.updatedAt != '' &&
                      resume?.updatedAt
                        ? setDetailDateFormat(resume?.updatedAt)
                        : '-'}
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="tac bg">소속대학 학년</td>
                    <td className="tac">
                      {resume?.affiliatedUniversity?.grade || '-'}
                    </td>
                    <td className="tac bg">소속대학 학과</td>
                    <td className="tac">
                      {resume?.affiliatedUniversity?.major || '-'}
                    </td>
                  </tr> */}
                  {
                    resume?.periods?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기간</td>
                          <td className="tac" colSpan="3">
                           {`${combineViewer(resume?.periods[0]?.startDate, resume?.periods[0]?.endDate, ' ~ ')} (${resume?.periods[0]?.durationEn})`}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.skills?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">능력</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.skills, 'skillEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.workTypes?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">업무 타입</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.workTypes, 'workTypeEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.jobPositions?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 업무</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.jobPositions, 'jobPositionEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.objectives?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">지원 목적</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.objectives, 'objectiveEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.certificates?.length > 0
                    ?
                      (
                        resume?.certificates?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">자격증{`(${idx+1})`}</td>
                          </tr>
                          <tr key={`${idx}-1`}>
                            <td className="tac bg">자격증명</td>
                            <td className="tac">
                              {item?.certificateEn || '-'}
                            </td>
                            <td className="tac bg">자격증 유효기간</td>
                            <td className="tac">
                              {combineViewer(item?.startDate, item?.endDate, ' ~ ')}
                            </td>
                          </tr>
                          <tr key={`${idx}-2`}>
                            <td className="tac bg">자격증 설명</td>
                            <td className="tac" colSpan="3">
                              {item?.certificateDescription || '-'}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  <tr>
                    <td className="tac bg" colSpan="4">희망 기업 정보</td>
                  </tr>
                  {
                    resume?.companyCountries?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기업 국가</td>
                          <td className="tac" colSpan="3">
                            {combineViewerArr(resume?.companyCountries, 'countryEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.companyCultures?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기업 문화</td>
                          <td className="tac" colSpan="3" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {combineViewerArr(resume?.companyCultures, 'companyCultureEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.companySizes?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기업 규모</td>
                          <td className="tac" colSpan="3" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {combineViewerArr(resume?.companySizes, 'companySizeEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.companySupports?.length > 0
                    ?
                      (
                        <tr>
                          <td className="tac bg">희망 기업 복지</td>
                          <td className="tac" colSpan="3" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {combineViewerArr(resume?.companySupports, 'companySupportEn', ' / ')}
                          </td>
                        </tr>
                      )
                    : <></>
                  }
                  {
                    resume?.experiences?.length > 0
                    ?
                      (
                        resume?.experiences?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">인턴 경험{`(${idx+1})`}</td>
                          </tr>
                          <tr key={`${idx}-1`}>
                            <td className="tac bg">기업명</td>
                            <td className="tac">
                              {item?.companyName || '-'}
                            </td>
                            <td className="tac bg">기간</td>
                            <td className="tac">
                              {`${combineViewer(item?.startDate, item?.endDate, ' ~ ')} (${item?.durationEn})`}
                            </td>
                          </tr>
                          <tr key={`${idx}-2`}>
                            <td className="tac bg">구분</td>
                            <td className="tac">
                              {item?.industryCategoryEn || '-'}
                            </td>
                            <td className="tac bg">담당한 일</td>
                            <td className="tac">
                              {item?.job || '-'}
                            </td>
                          </tr>
                          <tr key={`${idx}-3`}>
                            <td className="tac bg">결과</td>
                            <td className="tac">
                              {item?.outcome || '-'}
                            </td>
                            <td className="tac bg">포지션</td>
                            <td className="tac">
                              {item?.position || '-'}
                            </td>
                          </tr>
                          <tr key={`${idx}-4`}>
                            <td className="tac bg">급여</td>
                            <td className="tac" colSpan="3">
                              {item?.salary || '-'}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  {
                    resume?.highSchools?.length > 0
                    ?
                      (
                        resume?.highSchools?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">고등학교{`(${idx+1})`}</td>
                          </tr>
                          <tr key={idx}>
                            <td className="tac bg">고등학교명</td>
                            <td className="tac">
                              {item?.name || '-'}
                            </td>
                            <td className="tac bg">재학기간</td>
                            <td className="tac">
                              {combineViewer(item?.startDate, item?.endDate, ' ~ ')}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  {
                    resume?.universities?.length > 0
                    ?
                      (
                        resume?.universities?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">대학교{`(${idx+1})`}</td>
                          </tr>
                          <tr key={`${idx}-1`}>
                            <td className="tac bg">대학교명</td>
                            <td className="tac">
                              {item?.name || '-'}
                            </td>
                            <td className="tac bg">재학기간</td>
                            <td className="tac">
                              {combineViewer(item?.startDate, item?.endDate, ' ~ ')}
                            </td>
                          </tr>
                          <tr key={`${idx}-2`}>
                            <td className="tac bg">자격</td>
                            <td className="tac">
                              {item?.qualificationEn || '-'}
                            </td>
                            <td className="tac bg">연구분야</td>
                            <td className="tac">
                              {item?.studyFieldEn}
                            </td>
                          </tr>
                          <tr key={`${idx}-3`}>
                            <td className="tac bg">활동이력</td>
                            <td className="tac" colSpan="3">
                              {item?.universityActivity || '-'}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  {
                    resume?.otherLanguages?.length > 0
                    ?
                      (
                        resume?.otherLanguages?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">외국어{`(${idx+1})`}</td>
                          </tr>
                          <tr key={idx}>
                            <td className="tac bg">외국어</td>
                            <td className="tac">
                              {item?.languageEn || '-'}
                            </td>
                            <td className="tac bg">언어능력</td>
                            <td className="tac">
                            {item?.languageProficiencyEn || '-'}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                  {
                    resume?.personalCharacters?.length > 0
                    ?
                      (
                        resume?.personalCharacters?.map((item, idx) => (
                          <>
                          <tr>
                            <td className="tac bg" colSpan="4">수상이력{`(${idx+1})`}</td>
                          </tr>
                          <tr key={`${idx}-1`}>
                            <td className="tac bg">수상이름</td>
                            <td className="tac">
                              {item?.awardName || '-'}
                            </td>
                            <td className="tac bg">설명</td>
                            <td className="tac">
                              {item?.awardDescription || '-'}
                            </td>
                          </tr>
                          <tr key={`${idx}-2`}>
                            <td className="tac bg">수상기간</td>
                            <td className="tac" colSpan="3">
                              {combineViewer(item?.startDate, item?.endDate, ' ~ ')}
                            </td>
                          </tr>
                          </>
                        ))
                      )
                    : <></>
                  }
                </tbody>
              </table>
            </div>
          </div>

          {/** 썬더잡 점수 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">ThunderJob 점수</h6>
                {
                  !isEmpty(application) && application?.order?.product?.applicationStatus != 'CANCEL'
                  ?
                    <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                      onClick={(e) => { 
                        e.preventDefault()
                        setEditMode(!editMode)
                      }}
                    >
                      { !editMode ? '수정' : '보기' }
                    </a>
                  : <div></div>
                }
            </div>
            <div className="table-body">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">ThunderJob 점수</td>
                    <td className="tac">
                      <div className="d-flex">
                        <input type="number" id="title" max="10" className="int me-10" placeholder="제목" value={score} disabled={!editMode}
                          style={{width: '100px'}}
                          onInput={(e) => { 
                            if(e.target.value > 10) {
                              e.target.value = e.target.value.substr(0, e.target.value.length - 1)
                            }
                            e.target.value = onlyNumber(e.target.value) 
                          }}
                          onChange={(e) => {
                            setScore(e.target.value)
                          }} />
                        {
                          editMode ? (
                            <button type="submit" className="comm-btn bd-radius-5 btn-success me-10"
                              onClick={() => {
                                callThunderJobScoreSave()
                            }} >저장</button>   
                          )
                          : (
                            <></>
                          )
                        }
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/** 결제 정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">결제 정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">결제 상태</td>
                    <td className="tac">
                      {application?.status != 'REFUNDED' 
                        ? !isEmpty(application?.status)
                          ? application?.status
                          : '-'
                        : '-'}
                    </td>
                    <td className="tac bg">결제 일시</td>
                    <td className="tac">
                      {application?.paidAt != '' && application?.paidAt 
                        ? setDetailDateFormat(application?.paidAt) 
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">결제 수단</td>
                    <td className="tac">{application?.method || '-'}</td>
                    <td className="tac bg">결제 금액</td>
                    <td className="tac">{application?.amount || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">환불 상태</td>
                    <td className="tac">
                      {application?.status == 'REFUNDED' 
                        ? application?.status 
                        : '-'}
                    </td>
                    <td className="tac bg">환불 일시</td>
                    <td className="tac">
                      {application?.refundedAt != '' && application?.refundedAt 
                        ? setDetailDateFormat(application?.refundedAt) 
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default UserManagement
