import React from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { loginRoutes } from './Routes'

const LoginRoutes = () => {
  const location = useLocation()

  const match = loginRoutes.filter(({ path }) => path === location.pathname)

  return (
    <>
      {/* 404 페이지 처리 */}
      {!match.length && (
        <Routes>
          <Route path={`/*`} element={<Navigate to={`/login`} />} />
        </Routes>
      )}

      {match.length && (
        <Routes>
          (
          {loginRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
          )
        </Routes>
      )}
    </>
  )
}

export default LoginRoutes
