import React, { Fragment, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { MENUITEMS } from '../../data/menu'
import { setDateFormat } from '../../utils/index'
const Navigation = () => {
  const [title, setTitle] = useState('')
  const winUrl = window.location.pathname
  const menu = MENUITEMS.Items.filter(
    (menu) => menu.children.filter((ch) => winUrl.includes(ch.path)).length != 0
  )
  useEffect(() => {
    if(menu.length > 0 && Object.keys(menu[0]).includes('children')) {
      const findIdx = menu[0]?.children.findIndex((x) => {
        return winUrl.indexOf(x?.path) > -1
      })
      if(findIdx > -1) {
        const findIdx2 = menu[0]?.children[findIdx]?.children.findIndex((x) => {
          return winUrl.indexOf(x?.path) > -1
        })
        if(findIdx2 > -1) {
          setTitle(menu[0]?.children[findIdx]?.children[findIdx2]?.title)
        }else {
          setTitle(menu[0]?.children[findIdx]?.title)
        }
      }
    }
  }, [menu])
  
  return (
    <Fragment>
      {menu && menu.length > 0 ? (
        <div className="breadcrumb-head d-flex mb-30">
          {/* <h5 className="breadcrumb-title">{menu[0].title}</h5> */}
          <h5 className="breadcrumb-title">{title}</h5>
          <div className="breadcrumb">
            <Link className="home" to="/home">
              <i className="ico_home"></i> home
            </Link>
            <Link className="user" to={menu[0].children[0].path}>
              {menu[0].title}
            </Link>
            {menu[0].children.filter((ch) => ch.path == winUrl).length == 0 ? (
              <Link
                className="user"
                to={
                  menu[0].children.filter((ch) => winUrl.includes(ch.path))[0]
                    .path
                }
              >
                {
                  menu[0].children.filter((ch) => winUrl.includes(ch.path))[0]
                    .title
                }
              </Link>
            ) : (
              <strong className="position">
                {menu[0].children.filter((ch) => ch.path == winUrl)[0].title}
              </strong>
            )}
            {menu[0].children.filter((ch) => ch.path == winUrl).length == 0 && (
              <strong className="position">
                {
                  menu[0].children
                    .filter(
                      (ch) =>
                        ch?.children?.filter((dt) => winUrl.includes(dt.path))
                          .length != 0
                    )[0]
                    .children.filter((dt) => winUrl.includes(dt.path))[0].title
                }
              </strong>
            )}
          </div>
        </div>
      ) : (
        <div className="breadcrumb-head d-flex mb-30">
          <h5 className="breadcrumb-title">{setDateFormat(dayjs())} 주요 지표</h5>
          <div className="breadcrumb">
            <Link className="home" to="/home">
              <i className="ico_home"></i> home
            </Link>
            <strong className="position">Dashboard</strong>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default Navigation
