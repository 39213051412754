// 설정 변경시 서버 재시작
const config = {
  development: {
    APP_ENV: "dev",
    REACT_APP_ENV: "dev",
    BASE_API: "https://dev-api.thunderjob.com/bo/v1",
  },
  production: {
    APP_ENV: "prod",
    REACT_APP_ENV: "prod",
    BASE_API: "https://api.thunderjob.com/bo/v1",
  },
  stage: {
    APP_ENV: "stg",
    REACT_APP_ENV: "stg",
    BASE_API: "",
  },
  local: {
    APP_ENV: "local",
    REACT_APP_ENV: "local",
    BASE_API: "http://localhost:8080/bo/v1",
  },
};

// const env = config?.[process.env.APP_ENV];
const env = config?.[process.env.REACT_APP_ENV];
export default {
  ...(env ?? config["production"]),
};
