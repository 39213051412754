import Banner from '../components/system/banner/index'
import BannerDetail from '../components/system/banner/detail'

import AdminManagement from '../components/system/admin/index'
import AdminManagementDetail from '../components/system/admin/detail'
// eslint-disable-next-line no-undef, import/no-anonymous-default-export
export default [
  { path: `/system/banner`, Component: <Banner /> },
  { path: `/system/banner/detail`, Component: <BannerDetail /> },

  { path: `/system/admin`, Component: <AdminManagement /> },
  { path: `/system/admin/detail`, Component: <AdminManagementDetail /> },
]