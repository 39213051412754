import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import { getPreInterview, insertPreInterview, updatePreInterview, deletePreInterview } from '../../../api/post'
import { isEmpty } from '../../../utils/index'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const PreInterview = () => {
  const dispatch = useAppDispatch()

  const [application, setApplication] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [interviewIdx, setInterviewIdx] = useState(0)
  const [selectQuestion, setSelectQuestion] = useState([])

  const [addQuestion, setAddQuestion] = useState({
    question: '',
    answer: [{
      no: 1,
      answer: '',
      score: 0
    },
    {
      no: 2,
      answer: '',
      score: 0
    },
    {
      no: 3,
      answer: '',
      score: 0
    }]
  })
  useEffect(() => {
    dispatch(setLoading(false))
    // 기본정보
    callApplication()
  }, [])

  const callApplication = async () => {
    dispatch(setLoading(true))
    const { code, data } = await getPreInterview().finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      setApplication([...data])
      setInterviewIdx(0)
      setSelectQuestion([ ...data[0]?.question ])
    } else {
      setApplication({})
    }

    dispatch(setLoading(false))
  }

  const updateQuestionAnswer = async (idx) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('인터뷰 질문 및 답변을 수정하시겠습니까?')) {
      let scoreChk = true
      let emptyChk = false

      const tempApplication = {
        preInterviewId: application?.[interviewIdx]?.id,
        id: application?.[interviewIdx]?.question?.[idx]?.id,
        question: application?.[interviewIdx]?.question?.[idx]?.question,
        answer: []
      }

      application?.[interviewIdx]?.question?.[idx]?.answer.forEach((x) => { 
        tempApplication.answer.push({
          id: x.id,
          no: x.no,
          answer: x.answer,
          questionId: x.questionId,
          score: Number(x.score)
        })
        if(x.score === 1) scoreChk = false
        if(isEmpty(x.answer)) emptyChk = true
      })

      if(isEmpty(application?.[interviewIdx]?.question?.[idx]?.question)) {
        return alert('Question을 입력해주세요')
      }
      if(emptyChk) {
        return alert('Answer를 모두 입력해주세요')
      }
      if(scoreChk) {
        return alert('Score 중 최소 한 개는 1점을 입력해주세요')
      }

      dispatch(setLoading(true))

      const { code, msg } = await updatePreInterview(application?.[interviewIdx]?.question?.[idx]?.id, tempApplication).finally(() => {
        dispatch(setLoading(false))
      })

      if(code === 200) {
        alert('변경 성공')
      }else if(code === 608){
        alert(msg)
      }else {
        alert('변경 실패')
      }
    }
  }

  const insertQuestionAnswer = async () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('인터뷰 질문 및 답변을 등록하시겠습니까?')) {
      let scoreChk = true
      let emptyChk = false

      addQuestion.answer.forEach((x) => { 
        x.score = Number(x.score)
        if(x.score === 1) scoreChk = false
        if(isEmpty(x.answer)) emptyChk = true
      })

      if(isEmpty(addQuestion?.question)) {
        return alert('Question을 입력해주세요')
      }
      if(emptyChk) {
        return alert('Answer를 모두 입력해주세요')
      }
      if(scoreChk) {
        return alert('Score 중 최소 한 개는 1점을 입력해주세요')
      }

      dispatch(setLoading(true))

      const { code } = await insertPreInterview({ ...addQuestion, preInterviewId: application?.[interviewIdx]?.id }).finally(() => {
        dispatch(setLoading(false))
      })

      if(code === 200) {
        alert('저장 성공')
        window.location.reload()
      }else {
        alert('저장 실패')
      }
    }
  }

  const deleteQuestionAnswer = async (idx) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('인터뷰 질문 및 답변을 삭제하시겠습니까?')) {
      dispatch(setLoading(true))

      const { code, msg } = await deletePreInterview(application?.[interviewIdx]?.question?.[idx]?.id).finally(() => {
        dispatch(setLoading(false))
      })

      if(code === 200) {
        alert('삭제 성공')
        window.location.reload()
      }else if(code === 608){
        alert(msg)
      }else {
        alert('삭제 실패')
      }
    }
  }

  return (
    <Fragment>
      <Container>
      <div className="contents">
          <div className="card">
            <div className="card-head">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-center"><h6 className="card-title">사전 인터뷰 정보</h6></div>
                  <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                    onClick={(e) => { 
                      e.preventDefault()
                      setEditMode(!editMode)
                    }}
                  >
                    { !editMode ? '수정' : '보기' }
                  </a>
              </div>
            </div>
            <div className="card-body">
              {/* <div className="form-col mb-30">
                <label className="lbl">사전 인터뷰 카테고리 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int col-2">
                  <div className="d-flex">
                    <select id="active" className="int me-10" style={{ width: '150px' }}
                      onChange={(e) => {
                        setInterviewIdx(e.target.value)
                      }} 
                      value={interviewIdx}
                      disabled={!editMode}
                    >
                      {application?.length != 0 ? (
                        application?.map((item, idx) => (
                          <option value={ idx } key={ item?.id }>{item?.title}</option>
                        ))
                      ): (
                        <></>
                      )}
                    </select>
                  </div>
                </div>
              </div> */}
              {selectQuestion?.length > 0 ? (
                selectQuestion?.map((item, idx) => (
                  <div className="form-col mb-15" style={{display: 'block'}}>
                    <div className="form-col mb-15">
                      <label className="lbl">Question - {item?.no} <span className="required"><span>필수입력</span></span></label>
                      <div className="col-int">
                        <div className="d-flex">
                          <input type="text" className={`int ${!editMode ? '' : 'me-20'}`} placeholder={item?.question} key={item?.id} value={item?.question} disabled={!editMode}
                            onChange={(e) => {
                              const tempApplication = [ ...application ]
                              tempApplication[interviewIdx].question[idx].question = e.target.value
                              setApplication([ ...tempApplication ])
                              setSelectQuestion([ ...tempApplication[interviewIdx].question ])
                          }} />

                          <button type="submit" className="comm-btn me-5 bd-radius-5 btn-success" style={{ display: !editMode ? 'none' : 'block' }}
                            onClick={() => {
                              updateQuestionAnswer(idx)
                            }} >수정</button>

                          <button type="submit" className="comm-btn bd-radius-5 btn-danger" style={{ display: !editMode ? 'none' : 'block' }}
                            onClick={() => {
                              deleteQuestionAnswer(idx)
                            }} >삭제</button>
                        </div>
                      </div>
                    </div>
                    <div className="form-col mb-30 justify-content-start">
                      <label className="lbl"></label>
                        <div className="form-col ms-25" style={{width: '100%'}}>
                        {item?.answer?.length > 0 ? (
                          item?.answer?.map((aItem, aIdx) => (
                            <div className="form-col-custom mb-15">
                              <label className="lbl">Answer - {aItem?.no} <span className="required"><span>필수입력</span></span></label>
                              <div className="col-int">
                                <div className="d-flex">
                                  <input type="text" className="int me-5" style={{ width: '150px' }} placeholder={aItem?.answer} key={`answer${aItem?.id}`} value={aItem?.answer} disabled={!editMode}
                                    onChange={(e) => {
                                      const tempApplication = [ ...application ]
                                      tempApplication[interviewIdx].question[idx].answer[aIdx].answer = e.target.value
                                      setApplication([ ...tempApplication ])
                                  }} />

                                  <input type="number" className="int" style={{ width: '70px' }} placeholder={aItem?.score} key={`score${aItem?.id}`} value={aItem?.score} disabled={!editMode}
                                    onInput={(e) => {
                                      const regExp = /^([1-9]{1}\d{0,1}|0{1})(\.{1}\d{0,1})?$/g;
                                      if (!regExp.test(e.target.value)) {                                      
                                        e.target.value = e.target.value.substr(0, e.target.value.length - 1)
                                      }else if(e.target.value > 1) {
                                        e.target.value = e.target.value.substr(0, e.target.value.length - 1)
                                      }
                                    }}
                                    onChange={(e) => {
                                      const tempApplication = [ ...application ]
                                      tempApplication[interviewIdx].question[idx].answer[aIdx].score = e.target.value
                                      setApplication([ ...tempApplication ])
                                  }} />
                                </div>
                              </div>
                            </div>
                          ))
                        ): (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ): (
                <div className="form-col mb-15"></div>
              )}
              <hr className="hr-add-preinterview" />
              <div className="form-col pt-30 mb-15" style={{display: 'block'}}>
                <div className="form-col mb-15">
                  <label className="lbl">사전 인터뷰 <br/>Question 추가</label>
                  <div className="col-int">
                    <div className="d-flex">
                      <input type="text" className={`int ${!editMode ? '' : 'me-20'}`} placeholder="Question 추가" key="addQuestion" value={addQuestion?.question} disabled={!editMode}
                        onChange={(e) => {
                          const tempQuestion = { ...addQuestion }
                          tempQuestion.question = e.target.value
                          setAddQuestion({...tempQuestion})
                      }} />

                      <button type="submit" className="comm-btn bd-radius-5 btn-success" style={{ display: !editMode ? 'none' : 'block' }}
                        onClick={() => {
                          insertQuestionAnswer()
                        }} >추가</button>
                    </div>
                  </div>
                </div>
                <div className="form-col mb-30 justify-content-start">
                  <label className="lbl"></label>
                    <div className="form-col ms-25" style={{width: '100%'}}>
                    {addQuestion?.answer?.length > 0 ? (
                      addQuestion?.answer?.map((item, idx) => (
                        <div className="form-col-custom mb-15">
                          <label className="lbl">Answer - {idx+1}</label>
                          <div className="col-int">
                            <div className="d-flex">
                              <input type="text" className="int me-5" style={{ width: '150px' }} placeholder={`answer${item?.no}`} key={`add-answer${item?.no}`} value={item?.answer} disabled={!editMode}
                                onChange={(e) => {
                                  const tempQuestion = { ...addQuestion }
                                  tempQuestion.answer[idx].answer = e.target.value
                                  setAddQuestion({...tempQuestion})
                              }} />

                              <input type="number" className="int" style={{ width: '70px' }} placeholder={`score${item?.no}`} key={`add-score${item?.no}`} value={item?.score} disabled={!editMode}
                                onInput={(e) => {
                                  const regExp = /^([1-9]{1}\d{0,1}|0{1})(\.{1}\d{0,1})?$/g;
                                  if (!regExp.test(e.target.value)) {                                      
                                    e.target.value = e.target.value.substr(0, e.target.value.length - 1)
                                  }else if(e.target.value > 1) {
                                    e.target.value = e.target.value.substr(0, e.target.value.length - 1)
                                  }
                                }}
                                onChange={(e) => {
                                  const tempQuestion = { ...addQuestion }
                                  tempQuestion.answer[idx].score = e.target.value
                                  setAddQuestion({...tempQuestion})
                              }} />
                            </div>
                          </div>
                        </div>
                      ))
                    ): (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default PreInterview
