import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Container } from 'reactstrap'
import { useNavigate, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  getBannerDetail,
  insertBanners,
  updateBanners,
} from '../../../api/system'
import { getUrlParams, isEmpty, setDateTime } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const BannerManagement = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [params, setParams] = useState(null)
  const [editMode, setEditMode] = useState(false)

  const [banner, setBanner] = useState({})
  const [title, setTitle] = useState('')
  const [bannerType, setBannerType] = useState('TOP')
  const [content, setContent] = useState('')
  const [active, setActive] = useState(true)
  const [link, setLink] = useState('')
  const [linkType, setLinkType] = useState(true)

  const [startAt, setStartAt] = useState(new Date(dayjs().format('YYYY.MM.DD')))
  const [startTimeAt, setStartTimeAt] = useState(
    new Date(dayjs().format('YYYY.MM.DD 00:00'))
  )
  const [endAt, setEndAt] = useState(
    new Date(dayjs().add(1, 'M').format('YYYY.MM.DD'))
  )
  const [endTimeAt, setEndTimeAt] = useState(
    new Date(dayjs().add(1, 'M').format('YYYY.MM.DD 23:59'))
  )
  const [fileName, setFileName] = useState('')
  const [preview, setPreview] = useState('')
  // 등록 시 활용
  const [addImage, setAddImage] = useState({})

  useEffect(() => {
    dispatch(setLoading(false))

    const urlParams = getUrlParams(window.location.href)
    setParams(urlParams)

    if (urlParams?.mode != 'reg') {
      getBanner(urlParams)
      setEditMode(false)
    } else {
      setEditMode(true)
    }

    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const savePreview = (files, url = null) => {
    if (files !== null && files?.size > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(files)
      reader.onloadend = () => {
        setPreview(reader.result)
      }
    } else if (files !== null && files?.url) {
      setPreview(`${url}/${files?.url}`)
    } else {
      setPreview(null)
    }
  }

  const cancelContents = () => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(`작성한 내용을 저장하지 않고\n상위 페이지로 이동하시겠습니까?`)
    ) {
      window.location.reload()
    }
  }

  const dataCheck = () => {
    if (
      isEmpty(title) ||
      isEmpty(content) ||
      isEmpty(bannerType) ||
      // isEmpty(link) ||
      startAt == '' ||
      startAt == null ||
      startAt == undefined ||
      endAt == '' ||
      endAt == null ||
      endAt == undefined ||
      (!(addImage !== null && addImage?.size > 0) &&
        banner?.images.length === 0)
    ) {
      alert('필수 입력 항목을 입력해주세요')
      return false
    }
    if (dayjs(endAt).diff(dayjs(startAt), 'days') < 0) {
      alert('게시 기간을 체크 해주세요')
      return false
    } else {
      if (dayjs(startAt).diff(dayjs(endAt), 'days') === 0) {
        if (
          dayjs(
            `${dayjs(endAt).format('YYYY-MM-DD')} ${dayjs(endTimeAt).format(
              'HH:mm'
            )}`
          ).diff(
            `${dayjs(startAt).format('YYYY-MM-DD')} ${dayjs(startTimeAt).format(
              'HH:mm'
            )}`,
            'minutes'
          ) < 0
        ) {
          alert('게시 기간을 체크 해주세요')
          return false
        }
      }
    }

    return true
  }

  // 등록
  const insertBanner = async () => {
    if (!dataCheck()) return
    const formData = new FormData()
    formData.append('title', title)
    formData.append('content', content)
    formData.append('subType', bannerType)
    formData.append('link', link)
    formData.append('linkType', linkType ? 'EXTERNAL' : 'INTERNAL')
    formData.append(
      'startAt',
      setDateTime(
        `${dayjs(startAt).format('YYYY-MM-DD')} ${dayjs(startTimeAt).format(
          'HH:mm'
        )}`
      )
    )
    formData.append(
      'endAt',
      setDateTime(
        `${dayjs(endAt).format('YYYY-MM-DD')} ${dayjs(endTimeAt).format(
          'HH:mm'
        )}`
      )
    )
    formData.append('active', active)
    formData.append('uploadFiles', addImage)

    dispatch(setLoading(true))

    const { code, data } = await insertBanners(formData).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      alert('등록 완료')
      navigate('/system/banner')
    } else {
      alert('등록 실패')
    }
  }

  // 상세
  const getBanner = async (param) => {
    if (Object.keys(param?.bid).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getBannerDetail(param?.bid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        setBanner({ ...data })
        setTitle(data?.title)
        setBannerType(data?.subType)
        setContent(data?.content)
        setActive(data?.active)
        setLink(data?.link)
        setLinkType(data?.linkType === 'EXTERNAL' ? true : false)
        setStartAt(
          !isEmpty(data?.startAt)
            ? new Date(dayjs(data?.startAt).format('YYYY.MM.DD'))
            : new Date(dayjs().format('YYYY.MM.DD'))
        )
        setStartTimeAt(
          !isEmpty(data?.startAt)
            ? new Date(dayjs(data?.startAt).format('YYYY.MM.DD HH:mm'))
            : new Date(dayjs().format('YYYY.MM.DD 00:00'))
        )
        setEndAt(
          !isEmpty(data?.endAt)
            ? new Date(dayjs(data?.endAt).format('YYYY.MM.DD'))
            : new Date(dayjs().add(1, 'M').format('YYYY.MM.DD'))
        )
        setEndTimeAt(
          !isEmpty(data?.endAt)
            ? new Date(dayjs(data?.endAt).format('YYYY.MM.DD HH:mm'))
            : new Date(dayjs().add(1, 'M').format('YYYY.MM.DD HH:mm'))
        )
        setFileName(data?.images?.[0]?.originalFileName)
        savePreview(data?.images?.[0], data?.awsS3Url)
      } else {
        setBanner({})
      }
    }
  }

  // 수정
  const updateBanner = async () => {
    if (!dataCheck()) return

    const formData = new FormData()
    formData.append('title', title)
    formData.append('content', content)
    formData.append('subType', bannerType)
    formData.append('link', link)
    formData.append('linkType', linkType ? 'EXTERNAL' : 'INTERNAL')
    formData.append(
      'startAt',
      setDateTime(
        `${dayjs(startAt).format('YYYY-MM-DD')} ${dayjs(startTimeAt).format(
          'HH:mm'
        )}`
      )
    )
    formData.append(
      'endAt',
      setDateTime(
        `${dayjs(endAt).format('YYYY-MM-DD')} ${dayjs(endTimeAt).format(
          'HH:mm'
        )}`
      )
    )
    formData.append('active', active)

    if (addImage !== null && addImage?.size > 0) {
      formData.append('uploadFiles', addImage)
      const key = Object.keys(banner?.images[0])
      for (let i = 0; i < key.length; i++) {
        if (banner?.images[0][key[i]] !== null) {
          formData.append(`removeFiles[0].${key[i]}`, banner?.images[0][key[i]])
        }
      }
    }
    dispatch(setLoading(true))

    const { code, data } = await updateBanners(params?.bid, formData).finally(
      () => {
        dispatch(setLoading(false))
      }
    )

    if (code == 200) {
      alert('수정 완료')
    } else {
      alert('수정 실패')
    }

    // 리로드
    window.location.reload()
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <div className="card">
            <div className="card-head">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-center">
                  <h6 className="card-title">
                    {params?.mode == 'reg' ? '등록' : '상세보기 및 수정'}
                  </h6>
                </div>
                {params?.mode != 'reg' && (
                  <a
                    href="#"
                    className="comm-btn btn-md btn-gray bd-radius-3"
                    onClick={(e) => {
                      e.preventDefault()
                      setEditMode(!editMode)
                    }}
                  >
                    {!editMode ? '수정' : '보기'}
                  </a>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="form-col mb-15">
                <label className="lbl">
                  배너 타입{' '}
                  <span className="required">
                    <span>필수입력</span>
                  </span>
                </label>
                <div className="col-int">
                  <select
                    id="bannerType"
                    className="int"
                    style={{ width: '128px' }}
                    onChange={(e) => {
                      setBannerType(e.target.value)
                    }}
                    value={bannerType}
                    disabled={!editMode}
                  >
                    <option value={'TOP'} key={'TOP'}>
                      메인배너
                    </option>
                    <option value={'BOTTOM'} key={'BOTTOM'}>
                      하단배너
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">
                  제목{' '}
                  <span className="required">
                    <span>필수입력</span>
                  </span>
                </label>
                <div className="col-int col-2">
                  <div className="int-title int-text">
                    <input
                      type="text"
                      id="title"
                      className="int"
                      placeholder="제목"
                      value={title}
                      disabled={!editMode}
                      onChange={(e) => {
                        if (e.target.value.length <= 20) {
                          setTitle(e.target.value)
                        }
                      }}
                    />
                    <span className="element-text">
                      {title?.length ?? 0}/
                      <span className="text-primary">20</span>자
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">
                  배너 설명{' '}
                  <span className="required">
                    <span>필수입력</span>
                  </span>
                </label>
                <div className="col-int col-2">
                  <div className="int-title int-text">
                    <textarea
                      name="b_contents"
                      id="b_contents"
                      cols="30"
                      rows="5"
                      className="int int_textarea height_1"
                      placeholder="배너 설명"
                      value={content}
                      disabled={!editMode}
                      onChange={(e) => {
                        if (e.target.value.length <= 50) {
                          setContent(e.target.value)
                        }
                      }}
                    ></textarea>
                    <span className="element-text">
                      {content?.length ?? 0}/
                      <span className="text-primary">50</span>자
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">배너 링크 </label>
                <div className="col-int col-2">
                  <div className="d-flex">
                    <input
                      type="text"
                      id="title"
                      className="int me-10"
                      placeholder="링크"
                      value={link}
                      disabled={!editMode}
                      onChange={(e) => {
                        setLink(e.target.value)
                      }}
                    />
                    <input
                      type="checkbox"
                      id="link_checkbox"
                      checked={linkType}
                      disabled={!editMode}
                      onChange={() => setLinkType(!linkType)}
                    />
                    <label htmlFor="link_checkbox">
                      <span></span> 새창 열림
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">
                  게시 기간{' '}
                  <span className="required">
                    <span>필수입력</span>
                  </span>
                </label>
                <div className="col-int">
                  <div className="filter-int" style={{ alignItems: 'center' }}>
                    <input
                      type="date"
                      id="startAt"
                      className="int"
                      value={dayjs(startAt).format('YYYY-MM-DD')}
                      onChange={(e) => setStartAt(e.target.value)}
                      max={dayjs(endAt).format('YYYY-MM-DD')}
                      style={{ width: '150px', marginRight: '10px' }}
                      disabled={!editMode}
                    ></input>
                    <input
                      type="time"
                      id="startTimeAt"
                      className="int"
                      value={dayjs(startTimeAt).format('HH:mm:ss')}
                      onChange={(e) =>
                        setStartTimeAt(
                          dayjs().format(
                            `${dayjs(startAt).format('YYYY-MM-DD')} ${
                              e.target.value
                            }`
                          )
                        )
                      }
                      style={{ width: '150px', marginRight: '10px' }}
                      disabled={!editMode}
                    ></input>
                    ~
                    <input
                      type="date"
                      id="endAt"
                      className="int"
                      value={dayjs(endAt).format('YYYY-MM-DD')}
                      onChange={(e) => setEndAt(e.target.value)}
                      min={dayjs(startAt).format('YYYY-MM-DD')}
                      style={{
                        width: '150px',
                        marginLeft: '10px',
                        marginRight: '10px',
                      }}
                      disabled={!editMode}
                    ></input>
                    <input
                      type="time"
                      id="endTimeAt"
                      className="int"
                      value={dayjs(endTimeAt).format('HH:mm:ss')}
                      onChange={(e) =>
                        setEndTimeAt(
                          dayjs().format(
                            `${dayjs(endTimeAt).format('YYYY-MM-DD')} ${
                              e.target.value
                            }`
                          )
                        )
                      }
                      style={{ width: '150px', marginRight: '10px' }}
                      disabled={!editMode}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">
                  노출여부{' '}
                  <span className="required">
                    <span>필수입력</span>
                  </span>
                </label>
                <div className="col-int">
                  <select
                    id="active"
                    className="int"
                    style={{ width: '128px' }}
                    onChange={(e) => {
                      setActive(e.target.value)
                    }}
                    value={active ?? true}
                    disabled={!editMode}
                  >
                    <option value={true} key={true}>
                      노출
                    </option>
                    <option value={false} key={false}>
                      미노출
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-col mb-40">
                <label className="lbl">
                  파일 첨부{' '}
                  <span className="required">
                    <span>필수입력</span>
                  </span>
                </label>
                <div className="col-int col-2">
                  <div className="int-filebox">
                    <label
                      htmlFor="a_fileName"
                      className="comm-btn btn-secondary"
                    >
                      파일 선택
                    </label>
                    <input
                      className="upload-name int"
                      placeholder="파일을 선택하세요."
                      disabled="disabled"
                      value={fileName}
                    />
                    <input
                      type="file"
                      id="a_fileName"
                      className="upload-hidden"
                      disabled={!editMode}
                      onChange={(e) => {
                        if (isEmpty(e.target.value)) {
                          setFileName('')
                          setAddImage(null)
                          savePreview(null)
                        } else {
                          setFileName(
                            e.target.value.split('/').pop().split('\\').pop()
                          )
                          setAddImage(e.target.files[0])
                          savePreview(e.target.files[0])
                        }
                      }}
                    />
                  </div>
                  <p className="text-error">
                    [메인배너] &nbsp;&nbsp;1140 x 662
                  </p>
                  <p className="text-error">
                    [하단배너] &nbsp;&nbsp;1140 x 140
                  </p>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl"></label>
                <div className="col-int col-2">
                  {preview ? <img src={preview} alt="미리보기" /> : <div></div>}
                </div>
              </div>
            </div>
            {params?.mode == 'reg' ? (
              <div className="form-btn justify-content-center mt-30">
                <div className="me-10">
                  <button
                    type="submit"
                    className="comm-btn bd-radius-5 btn-danger"
                    onClick={() => cancelContents()}
                  >
                    취소
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="comm-btn bd-radius-5 btn-gray"
                    onClick={() => insertBanner()}
                  >
                    저장
                  </button>
                </div>
              </div>
            ) : editMode ? (
              <div className="form-btn justify-content-center mt-30">
                <div className="me-10">
                  <button
                    type="submit"
                    className="comm-btn bd-radius-5 btn-danger"
                    onClick={() => cancelContents()}
                  >
                    취소
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="comm-btn bd-radius-5 btn-gray"
                    onClick={() => updateBanner()}
                  >
                    수정
                  </button>
                </div>
              </div>
            ) : (
              <div className="form-btn justify-content-center mt-30">
                <Link to={`/system/banner`}>
                  <button
                    type="submit"
                    className="comm-btn bd-radius-5 btn-gray"
                    onClick={() => {}}
                  >
                    목록
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default BannerManagement
