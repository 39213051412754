import React from 'react'
import { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import LayoutRoutes from './LayoutRoutes'
import LoginRoutes from './LoginRoutes'
import store from '../redux/store'
import { Loading } from '../components/common/loading'

const Routers = (props) => {
  const abortController = new AbortController()
  /* 
  테마 값 고정 하기 [Dubai] 에 해당 하는 부분을 원하는 테마로 고정
  테마 파일은 /data/layouts 경로 참조
  */
  const layout = 'Singapore' // localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const [token, setToken] = useState('')

  useEffect(() => {
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed']
    console.disableYellowBox = true
    return function cleanup() {
      abortController.abort()
    }
  }, [abortController])

  const onChange = () => {
    setToken(store.getState().token.token)
  }
  store.subscribe(onChange)

  return (
    <BrowserRouter basename={'/'}>
      <>
        {/* <Suspense fallback={<Loader />}> */}
        <Routes>
          {store.getState().token.token != '' ? (
            <>
              <Route exact path={``} element={<Navigate to={`/home`} />} />
              <Route path={`/*`} element={<LayoutRoutes />} />
            </>
          ) : (
            <Route path={`/*`} element={<LoginRoutes />} />
          )}
        </Routes>
        <Loading />
        {/* </Suspense> */}
      </>
    </BrowserRouter>
  )
}

export default Routers
