import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { getApplicationGlobalCamp, updateApplicationCancel, updateApplicationPaid, updateApplicationRefund } from '../../../api/member'
import { getUrlParams, setDateFormat, setDetailDateFormat, isEmpty, onlyNumber } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const UserManagement = () => {
  const dispatch = useAppDispatch()

  const [application, setApplication] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [cost, setCost] = useState(0)
  const [params, setParams] = useState(null)
  useEffect(() => {
    dispatch(setLoading(false))
    
    const paramInit = getUrlParams(window.location.href)
    setParams(paramInit)
    // 기본정보
    callApplication(paramInit)
    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const callApplication = async (param) => {
    if(Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getApplicationGlobalCamp({
        orderType: 'GLOBAL_CAMP',
        paymentId: param?.pid ?? '',
        orderId: param?.oid,
        searchType: 'APPLICATION',
      }).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200 && data.totalCount > 0) {
        setApplication({...data.payments[0]})
      } else {
        setApplication({})
      }
    }
  }

  // 결제 처리
  const applicationPaid = async () => {
    if(isEmpty(cost)) {
      return alert('결제 금액을 입력해주세요.')
    }

    // eslint-disable-next-line no-restricted-globals
    if(confirm('결제 처리 하시겠습니까?')) {
      dispatch(setLoading(true))

      const { code } = await updateApplicationPaid(params?.oid, { cost }).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        alert('결제 처리 성공')
        window.location.reload()
      } else {
        alert('결제 처리 실패')
      }
    }
  }

  // 결제 전 지원 취소(환불 X)
  const applicationCancel = async () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('지원 취소 하시겠습니까?')) {
      dispatch(setLoading(true))

      const { code } = await updateApplicationCancel(params?.oid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        alert('지원 취소 성공')
        window.location.reload()
      } else {
        alert('지원 취소 실패')
      }
    }
  }

  // 결제 이후 지원 취소(환불 O)
  const applicationRefund = async () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('환불 처리 하시겠습니까?')) {
      dispatch(setLoading(true))

      const { code } = await updateApplicationRefund(application?.id, { gcOrderId: params?.oid, cost: application?.amount }).finally(() => {
        dispatch(setLoading(false))
      })
      
      if (code == 200) {
        alert('환불 처리 성공')
        window.location.reload()
      } else {
        alert('환불 처리 실패')
      }
    }
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          {/** 기본정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">기본 정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">ID</td>
                    <td className="tac">{application?.order?.product?.account || '-'}</td>
                    <td className="tac bg">이름</td>
                    <td className="tac">{application?.order?.product?.userName || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">성별</td>
                    <td className="tac">{application?.order?.product?.genderNm || '-'}</td>
                    <td className="tac bg">생년월일</td>
                    <td className="tac">
                      {application?.order?.product?.birthday != '' && application?.order?.product?.birthday 
                        ? setDateFormat(application?.order?.product?.birthday) 
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">국적</td>
                    <td className="tac">{application?.order?.product?.nationalityNm || '-'}</td>
                    <td className="tac bg">연락처</td>
                    <td className="tac">{application?.order?.product?.phone || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">주소</td>
                    <td className="tac">{
                      !isEmpty(application?.order?.product?.address) || !isEmpty(application?.order?.product?.addressDetail)
                      ? application?.order?.product?.address + ' ' + application?.order?.product?.addressDetail
                      : '-'
                    }</td>
                    <td className="tac bg">SNS계정</td>
                    <td className="tac">{application?.order?.product?.snsId || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">제휴 대학</td>
                    <td className="tac">{application?.order?.product?.universityName || '-'}</td>
                    <td className="tac bg">기타사항</td>
                    <td className="tac">{application?.order?.product?.otherInformation || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/** 신청 정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">신청 정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">캠프명</td>
                    <td className="tac">{application?.order?.product?.name || '-'}</td>
                    <td className="tac bg">신청일시</td>
                    <td className="tac">
                      {application?.order?.product?.createdAt != '' && application?.order?.product?.createdAt 
                        ? setDetailDateFormat(application?.order?.product?.createdAt) 
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/** 결제 정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
              <h6 className="table-title">결제 정보</h6>
              {
                !isEmpty(application) && application?.order?.product?.applicationStatus != 'CANCEL'
                ?
                  <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                    onClick={(e) => { 
                      e.preventDefault()
                      setEditMode(!editMode)
                    }}
                  >
                    { !editMode ? '수정' : '보기' }
                  </a>
                : <div></div>
              }
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">결제 상태</td>
                    <td className="tac">
                      {application?.status != 'REFUNDED' && !isEmpty(application?.status)
                        ? application?.status 
                        : '-'}
                    </td>
                    <td className="tac bg">결제 일시</td>
                    <td className="tac">
                      {application?.paidAt != '' && application?.paidAt 
                        ? setDetailDateFormat(application?.paidAt) 
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">결제 수단</td>
                    <td className="tac">{application?.method || '-'}</td>
                    <td className="tac bg">결제 금액</td>
                    <td className="tac">{application?.amount || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">환불 상태</td>
                    <td className="tac">
                      {application?.status == 'REFUNDED' 
                        ? application?.status 
                        : '-'}
                    </td>
                    <td className="tac bg">환불 일시</td>
                    <td className="tac">
                      {application?.refundedAt != '' && application?.refundedAt 
                        ? setDetailDateFormat(application?.refundedAt) 
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {
            editMode 
              ? (
                <div className="card">
                  {/* <div className="card-head">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-center"><h6 className="card-title">결제 금액 설정 및 상태 변경</h6></div>
                    </div>
                  </div> */}
                  <div className="card-body">
                    {
                      isEmpty(application?.status)
                      ? (
                        <div className="form-col">
                          <label className="lbl">결제 금액 <span className="required"><span>필수입력</span></span></label>
                          <div className="col-int col-2">
                            <div className="d-flex">
                              <input type="number" id="title" className="int me-10" placeholder="제목" value={cost} disabled={!editMode}
                                style={{width: '180px'}}
                                onInput={(e) => { e.target.value = onlyNumber(e.target.value) }}
                                onChange={(e) => {
                                  setCost(e.target.value)
                                }} />
                              {/* 결제 */}
                              <button type="submit" className="comm-btn bd-radius-5 btn-gray me-10"
                                onClick={() => {
                                  applicationPaid()
                                }} >결제 금액 저장</button>
                              
                              {/* 결제 전 지원 취소 */}
                              <button type="submit" className="comm-btn bd-radius-5 btn-danger me-10"
                                onClick={() => {
                                  applicationCancel()
                                }} >지원 취소</button>
                            </div>
                          </div>
                        </div>
                      )
                      : (
                        <div className="form-col">
                          <label className="lbl">결제 상태 변경 <span className="required"><span>필수입력</span></span></label>
                          <div className="col-int col-2">
                            {/* 환불 */}
                            <button type="submit" className="comm-btn bd-radius-5 btn-danger"
                            onClick={() => {
                              applicationRefund()
                            }} >환불 및 취소</button>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              )
              : (
                <div className="card">
                  <Link to={`/contents/globalcamp/application`}>
                    <button type="submit" className="comm-btn bd-radius-5 btn-gray">목록으로</button>
                  </Link>
                </div>
              )
            }
        </div>
      </Container>
    </Fragment>
  )
}

export default UserManagement
