// import store from '@redux/store'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

/*
  3자리수 콤마
*/
export const comma = (number, currency = '') => {
  const strNum = String(number)
  const split = strNum.split('.')
  const retunStr = [split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')]

  if (currency !== 'KRW') {
    retunStr.push(split.length > 1 ? `.${split[1]}` : '')
  }
  return retunStr.join('')
}

/*
  시간 변환
*/
export const time = (number) => {
  let timeString = ''
  const hour = parseInt(String(number / 3600))
  number -= hour * 3600
  const minute = parseInt(String(number / 60))
  number -= minute * 60
  const second = number
  if (hour != 0) {
    timeString += String(hour) + '시간'
  }
  if (minute != 0) {
    timeString += String(minute) + '분'
  }
  timeString += String(second)
  timeString += '초'
  return timeString
}

export const minute = (number) => {
  let timeString = ''
  const minute = parseInt(String(number / 60))

  if (minute != 0) {
    timeString += String(minute) + '분'
  }

  return timeString
}

/**
 * 유저 정보 갱신
 */
export const updateUserInfo = async () => {
  // const { code, data } = await getInfo()
  // if (!code) {
  //   store.dispatch(setUser(data))
  //   return true
  // }
  // return false
}

/**
 * url 에서 GET Parameter
 * @param str
 * @returns
 */
export const getUrlParams = (str) => {
  const params = {}
  str.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
    params[key] = value
  })
  return params
}

/**
 * 회원 인증 라우팅 처리
 */
/** 
export const goAuthRoute = async (router, path = '/mypage') => {
  const state = store.getState()
  const token = state.token

  if (token?.token) {
    // 로그인 정보 갱신
    const { code, data } = await apiValidToken()
    console.log(code, data, 'valid token')

    if (code || !data.result) {
      console.log('갱신 만료')
      // 갱신시도
      const customAxios = axios.create({})
      const res = await customAxios
        .get(`${process.env.BASE_API}/user/token/refresh`, {
          headers: {
            refresh: state.token.refresh,
          },
        })
        .catch(() => {
          return { code: 500 }
        })
      const { returnCode: code } = res?.data

      if (code) {
        alert('로그인이 만료 되었습니다.\n다시 로그인해 주세요.')

        store.dispatch(delToken())
        store.dispatch(delUser())
        return
      }

      // 만료가아니면
      // 토큰 갱신
      const token = {
        token: res?.headers?.authorization,
        refresh: res?.headers?.refresh,
      }

      console.log('갱신토큰', token)

      store.dispatch(setToken(token))
    }

    if (path) router.push(path)
  } else {
    alert('로그인 후 이용해 주세요.')
  }
}
*/

export const setCookie = (cname, cvalue, exdays) => {
  // if (process?.client) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
  // }
}

export const getCookie = (cname) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

// 자릿수 파라미터 반환
export const getPracticeCode = (cateCode) => {
  let tmp = ''

  if (cateCode == 'TA01') tmp = 'D'
  else if (cateCode == 'TA02') tmp = 'LT'
  else if (cateCode == 'TA03') tmp = 'LB'
  else if (cateCode == 'TA04') tmp = 'RT'
  else if (cateCode == 'TA05') tmp = 'C'
  else if (cateCode == 'TA06') tmp = 'RB'
  else if (cateCode == 'TA07') tmp = 'A'
  else if (cateCode == 'TA08') tmp = 'N'
  else if (cateCode == 'TA09') tmp = 'W'
  else if (cateCode == 'TA10') tmp = 'SW'
  else if (cateCode == 'TA11') tmp = 'LW'
  else tmp = ''

  return tmp
}

// 서버 url 세팅
export const setServerUrl = (url) => {
  const server = 'https://d3t4wtvu44pp3t.cloudfront.net'
  if (url.charAt(0) == '/') {
    return server + url
  } else {
    return server + '/' + url
  }
}

// 날짜 형식 반환
export const setDateFormat = (date, format) => {
  return isEmpty(date) ? dayjs().format(format ?? 'YYYY.MM.DD') : dayjs(date).format(format ?? 'YYYY.MM.DD')
}

// 날짜 형식 반환
export const setDetailDateFormat = (date) => {
  return dayjs(date).format('YYYY.MM.DD HH:mm')
}

// 날짜 형식 반환
export const setDateTime = (date) => {
  return dayjs(date).utc().format()
}

// 날짜간 차이 반환
export const calculateDateTerm = (date, date1) => {
  if (date1) {
    return Math.floor(dayjs(date1).diff(date, 'day', true))
  } else {
    return Math.floor(dayjs(date).diff(dayjs(), 'day', true))
  }
}

// 핸드폰 번호 형식 반환
export const phoneNum = (str) => {
  if (str) {
    return str.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
  } else {
    return ''
  }
}

// 전화 번호 형식 반환
export const telNum = (str) => {
  if (str) {
    return str.replace(/^(\d{0,2})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
  } else {
    return ''
  }
}

export const isEmpty = (value) => {
  if (
    value == '' ||
    value == null ||
    value == undefined ||
    (value != null && typeof value == 'object' && !Object.keys(value).length)
  ) {
    return true
  } else {
    return false
  }
}

export const onlyNumber = (value) => {
  return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
}

export const chunk = (data, size) => {
  const arr = []

  if (data) {
    for (let i = 0; i < data.length; i += size) {
      arr.push(data?.slice(i, i + size))
    }
  }

  return arr
}

// export const isValedTime = (value, format) => {
//   // YYYY-MM-DD HH:mm:ss.SSS
//   if (format)
//       return dayjs(value, 'YYYY-MM-DD', true).isValid() ? true : false
//   else return dayjs(value, format, true).isValid() ? true : false
// }

// export const utcTimeChg = (date, format) => {
//   const offset = (new Date().getTimezoneOffset() * -1) / 60

//   if (isEmpty(date)) return dayjs().format(format ?? 'YYYY-MM-DD HH:mm:ss')
//   return dayjs(date)
//       .add(offset, 'h')
//       .format(format ?? 'YYYY-MM-DD HH:mm:ss')
// }